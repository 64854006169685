import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from "gatsby"
import BaseTemplate from '../../templates/BaseTemplate';
import PathCard from '../../components/PathCard';
import TrailCards from '../../components/TrailCards';
import Carousel from "../../components/Carousel";

// Tell Webpack this JS file uses this images
import arrowGrowing from '../../assets/images/arrow-growing.png';
import forOrganizations from '../../assets/images/for-organizations.png';
import forIndividuals from '../../assets/images/for-individuals.png';
import wefResourceImg from '../../assets/images/wef-resource-tile.jpeg';
import absaResourceImg from '../../assets/images/absa-resource-tile.png';
import fortinetResourceImg from '../../assets/images/fortinet-resource-tile.jpg';
import gcaResourceImg from '../../assets/images/GCA-resource-tile.png';
import thLiveResourceImg from '../../assets/images/thlive-resource-tile.png';
import newDigitalArmyResourceImg from '../../assets/images/digital-army-resource-tile.jpeg';

const cardsData = graphql`
  query allData {
    allDataJson{
      edges{
        node{
          cards {
            title
            description
            imageUrl
            background,
            slug
          }
        }
      }
    }
    allTrailCardsDataJson(filter: {appearsIn: {glob: "*index*"}}) {
      edges {
        node {
          duration
          description
          color
          image
          imageHasBackground
          title
          type
          url
        }
      }
    }
  }
`;

const CardsQuery = () => (
  <StaticQuery
    query={cardsData}
    render={ data => {
      let cardsArr = [];
      data.allDataJson.edges.forEach((edge) => {
        const { cards } = edge.node;
        if( cards !== null ){
            cardsArr = cards;
        }
      });
      let trailCards = data.allTrailCardsDataJson.edges.map(({node}) => node);
      return (
        <IndexPage cards={cardsArr} trailCards={trailCards}/>
      )
    }}
  />
);

function closeModal(source){
  const modal = document.getElementById(source);
  if(source == "wef-jvc-modal"){
    var players = VidyardV4.api.getPlayersByUUID('R1cykK64oW1uu9EA2EF4pN');
    players[0].pause();
  }
  if(source == "wef-absa-modal"){
    try{
      ytPlayer.pauseVideo();
    }
    catch(e){
      console.log('Error closing video: ');
      console.log(e.name + ': ' + e.message);
    }
    
  }
  modal.classList.remove("slds-show");
  modal.classList.add("slds-hide");
}

function openModal(source){
  const modal = document.getElementById(source);
  modal.classList.remove("slds-hide");
  modal.classList.add("slds-show");
  if(source == "wef-absa-modal"){
    if(!playerReady){
      initPlayer();
    }
    else{
      ytPlayer.playVideo();
    }
  }
  if(source == "wef-jvc-modal"){
    var players = VidyardV4.api.getPlayersByUUID('R1cykK64oW1uu9EA2EF4pN');
    players[0].play();
  }
}

const IndexPage = ({cards, trailCards}) => {
  const cardsHtmlSection = [];
  cards.forEach((card, index) => {
    const i = index + 1;
    const id = card.title.replace(/\s/g, '-').toLowerCase();
    cardsHtmlSection.push(
      <PathCard
        key={i}
        index={i}
        title={card.title}
        description={card.description}
        imageUrl={card.imageUrl}
        background={card.background}
        id={id}
        slug={card.slug}
      />
    );
  });

  return (
    <BaseTemplate page="index">
      <Helmet>
        <script>
        {`
          var ytPlayer;
          var ytApiReady = false;
          var playerReady = false;
          
          var tag = document.createElement('script');
          tag.src = "https://www.youtube.com/iframe_api";
          var firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            
          function onYouTubeIframeAPIReady() {
             ytApiReady = true;
          }       
      
          function initPlayer(){
           if(ytApiReady){
              ytPlayer = new YT.Player('wef-youtube-wrapper', {
                videoId: 'HoopnpaaYA8',
                events: {
                  'onReady': onPlayerReady
                }
              });
            }
            else{
              setTimeout(initPlayer, 1000);
            }
          }
           
          function onPlayerReady(event) {
            playerReady = true;
            event.target.playVideo();
          }

          (function(window, document, undefined) {
            "use strict";
          
            // List of Video Vendors embeds you want to support
            var players = ['iframe[src*="youtube.com"]', 'iframe[src*="vidyard.com"]'];
          
            // Select videos
            var fitVids = document.querySelectorAll(players.join(","));
          
            // If there are videos on the page...
            if (fitVids.length) {
              // Loop through videos
              for (var i = 0; i < fitVids.length; i++) {
                // Get Video Information
                var fitVid = fitVids[i];
                var width = fitVid.getAttribute("width");
                var height = fitVid.getAttribute("height");
                var aspectRatio = height / width;
                var parentDiv = fitVid.parentNode;
          
                // Wrap it in a DIV
                var div = document.createElement("div");
                div.className = "wef-video-wrapper";
                div.style.paddingBottom = aspectRatio * 100 + "%";
                parentDiv.insertBefore(div, fitVid);
                fitVid.remove();
                div.appendChild(fitVid);
          
                // Clear height/width from fitVid
                fitVid.removeAttribute("height");
                fitVid.removeAttribute("width");
              }
            }
          })(window, document);
          
          `}
        </script>

      </Helmet> 
      <div className="wef-hero-home">
        <div className="wef-hero-home-bg"></div>
        <div className="custom-container--x-large slds-container--center H(100%) custom-align--vertical-center slds-is-relative">
          <div>
            <div className="wef-hero_title">
              Learn in-demand cybersecurity skills
            </div>
            {
              // <div className="wef-hero_description">
              //   Learn. Collaborate. <b>Develop.</b>
              // </div>
            }
          </div>
        </div>
      </div>

      <div className="wef-content--home">
        <div className="Bgc(gray) slds-p-horizontal--medium slds-p-hortizontal--medium">
          <div className="Fz(32) Fw(b) Lh(42) Maw(720) slds-container--center Pt(45) slds-text-align--center">
            Cybersecurity is more important than ever
          </div>

          <div className="slds-p-bottom--x-large">
            <div className="slds-container--large slds-container--center">
              <div className="slds-grid slds-grid--align-center slds-wrap slds-grid_pull-padded-medium slds-p-top--medium">
                <div className="slds-card tds-card wef-card-type-1 slds-align_absolute-center slds-p-horizontal--medium">
                  <div>
                    <img className="slds-p-right--large" src={arrowGrowing} />
                  </div>
                  <div className="Maw(150) slds-text-align--center">
                    <div className="Fz(48) Lh(62) Fw(b)">$6T</div>
                    <div className="Fz(14)">
                    estimated cost of cybercrime by 2021<span className="custom-uppertext">1</span>
                    </div>
                  </div>
                </div>
                <div className="slds-card tds-card wef-card-type-1 slds-align_absolute-center slds-p-horizontal--medium">
                  <div>
                    <img className="slds-p-right--large" src={arrowGrowing} />
                  </div>
                  <div className="Maw(150) slds-text-align--center">
                    <div className="Fz(48) Lh(62) Fw(b)">67%</div>
                    <div className="Fz(14)">
                    increase in cybercrime over the last five years<span className="custom-uppertext">2</span>
                    </div>
                  </div>
                </div>
                <div className="slds-card tds-card wef-card-type-1 slds-align_absolute-center slds-p-horizontal--medium">
                  <div>
                    <img className="slds-p-right--large" src={arrowGrowing} />
                  </div>
                  <div className="Maw(150) slds-text-align--center">
                    <div className="Fz(48) Lh(62) Fw(b)">3.5X</div>
                    <div className="Fz(14)">
                      annual growth of ransomware attacks<span className="custom-uppertext">3</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wef-section">
          <div className="wef-section_title">
            Skill-up On The Latest In Cybersecurity
          </div>
          <div className="slds-text-align--center Maw(390) slds-container--center">
            Free learning for companies and individuals of all levels to develop security knowledge.
          </div>
          <div className="slds-container--x-large slds-container--center">
            <div className="slds-grid slds-wrap slds-grid_align-center slds-grid_pull-padded-large slds-m-top--xx-large">
              <div className="slds-p-horizontal--large slds-text-align_center">
                <a href="organizations" className="D(b)">
                  <img src={forOrganizations} />
                </a>
                <div className="wef-section_title slds-m-top--large">For Organizations</div>
                <div className="Fz(18) slds-m-bottom--large">Find out how to secure your organization and team</div>
                <a href="organizations" className="tds-button_small tds-button_brand slds-button_brand slds-button slds-show_inline-block">Learn More</a>
              </div>
              <div className="slds-p-horizontal--large slds-text-align_center custom-medium-p-top--xx-large">
                <a href="individuals" className="D(b)">
                  <img src={forIndividuals} />
                </a>
                <div className="wef-section_title slds-m-top--large">For Individuals</div>
                <div className="Fz(18) slds-m-bottom--large">Learn new skills to get a top job in cybersecurity</div>
                <a href="individuals" className="tds-button_small tds-button_brand slds-button_brand slds-button slds-show_inline-block">Learn More</a>
              </div>
            </div>
          </div>
        </div>
        <div className="wef-section wef-section-bottom-space--x-large Bgc(gray)">
          <div className="wef-section_title">
          Learn About Cybersecurity on These Guided Paths
          </div>
          <div className="custom-container--x-large slds-container--center slds-m-top--medium">
            <TrailCards trailCards={trailCards}/>
          </div>
        </div>

        <div className="wef-section wef-section-bottom-space--x-large Bgc(gray) wef-section-border-gray">
          <div className="wef-section_title">
            Which cybersecurity job is right for you?
          </div>

          <div className="custom-container--x-large slds-container--center">
            <div className="slds-grid slds-wrap slds-grid_align-center slds-m-top--x-large slds-grid--pull-padded-medium">
              {cardsHtmlSection}
            </div>
          </div>
        </div>

        <div className="wef-section">
          <div className="wef-section_title">
          Meet real security professionals and read their stories
          </div>
          <Carousel></Carousel>
          
        </div>
        <div className="wef-section wef-section-border-gray">
          <div className="wef-section_title">
            Resources
          </div> 
          <div className="custom-container--xx-large slds-container--center slds-m-top--medium">
            <div className="slds-grid slds-wrap slds-gutters_small slds-grid_align-center slds-grid_vertical-stretch">
              <div className="slds-col slds-text-body_small wef-resource-tile slds-m-bottom_xx-large slds-m-horizontal_small">
                <div className="wef-resource-tile_img slds-m-bottom_small" style={{backgroundImage: `url(${fortinetResourceImg})`}} ></div>
                <div className="wef-resource-tile_text">
                  <div className="wef-resource-tile_title slds-m-bottom_x-small slds-m-top_small">Fortinet NSE Training Institute</div>
                  <div className="wef-resource-tile_desc slds-m-bottom_medium">Free cybersecurity training for all levels.</div>
                </div>
                <div>
                  <div className="slds-align-bottom">
                    <a href="https://www.fortinet.com/training/cybersecurity-professionals?utm_source=display&utm_medium=home-resources&utm_campaign=trailhead-partner-free-training" target="_blank" className="tds-button_small tds-button_brand slds-button_neutral slds-button slds-show_inline-block">Read More</a>
                  </div>
                </div>
              </div>

              <div className="slds-col  slds-text-body_small wef-resource-tile slds-m-bottom_xx-large slds-m-horizontal_small">
                <div className="wef-resource-tile_img slds-m-bottom_small" style={{backgroundImage: `url(${gcaResourceImg})`}}></div>
                <div className="wef-resource-tile_text">
                  <div className="wef-resource-tile_title slds-m-bottom_x-small slds-m-top_small">Global Cyber Alliance Toolkit</div>
                  <div className="wef-resource-tile_desc slds-m-bottom_medium">Discover free and effective tools to reduce cyber risk as a small business.</div>
                </div>
                <div className="">
                <a href="https://www.globalcyberalliance.org/gca-cybersecurity-toolkit/" target="_blank" className="tds-button_small tds-button_brand slds-button_neutral slds-button slds-show_inline-block">Read More</a>
                </div>
              </div>
            
              <div className="slds-col slds-text-body_small wef-resource-tile slds-m-bottom_xx-large slds-m-horizontal_small">
                <div className="wef-resource-tile_img slds-m-bottom_small" style={{backgroundImage: `url(${wefResourceImg})`}}></div>
                <div className="wef-resource-tile_text">
                  <div className="wef-resource-tile_title slds-m-bottom_x-small slds-m-top_small">World Economic Forum Centre for Cybersecurity</div>
                  <div className="wef-resource-tile_desc slds-m-bottom_medium">Strengthening global collaboration to address systemic cybersecurity challenges.</div>
                </div>
                <div className="">
                <a href="https://www.weforum.org/platforms/shaping-the-future-of-cybersecurity-and-digital-trust" target="_blank" className="tds-button_small tds-button_brand slds-button_neutral slds-button slds-show_inline-block">Read More</a>
                </div>
              </div>

              <div className="slds-col  slds-text-body_small wef-resource-tile slds-m-bottom_xx-large slds-m-horizontal_small">
                <div className="wef-resource-tile_img slds-m-bottom_small" style={{backgroundImage: `url(${absaResourceImg})`}} ></div>
                <div className="wef-resource-tile_text">
                  <div className="wef-resource-tile_title slds-m-bottom_x-small slds-m-top_small">Absa Cybersecurity Academy</div>
                  <div className="wef-resource-tile_desc slds-m-bottom_medium">Learn how the Absa Cybersecurity Academy in partnership with the Maharishi Institute is changing lives.</div>
                </div>
                <div className="">
                <button className="tds-button_small tds-button_brand slds-button_neutral slds-button slds-show_inline-block" onClick={() => openModal('wef-absa-modal')}>Watch Video</button>
                </div>
              </div>

              <div className="slds-col slds-text-body_small wef-resource-tile slds-m-bottom_xx-large slds-m-horizontal_small">
                <div className="wef-resource-tile_img slds-m-bottom_small" style={{backgroundSize:'contain', backgroundImage: `url(${thLiveResourceImg})`}}></div>
                <div className="wef-resource-tile_text">
                  <div className="wef-resource-tile_title slds-m-bottom_x-small slds-m-top_small">Cybersecurity Career Chats</div>
                  <div className="wef-resource-tile_desc slds-m-bottom_medium">Learn from experts in the field!</div>
                </div>
                <div className="">
                <button className="tds-button_small tds-button_brand slds-button_neutral slds-button slds-show_inline-block" onClick={() => openModal('wef-jvc-modal')}>Watch Video</button>
                </div>
              </div>

              <div className="slds-col slds-text-body_small wef-resource-tile slds-m-bottom_xx-large slds-m-horizontal_small">
                <div className="wef-resource-tile_img slds-m-bottom_small" style={{backgroundImage: `url(${newDigitalArmyResourceImg})`}} ></div>
                <div className="wef-resource-tile_text">
                  <div className="wef-resource-tile_title slds-m-bottom_x-small slds-m-top_small">World Economic Forum</div>
                  <div className="wef-resource-tile_desc slds-m-bottom_medium">
                    <p>We urgently need a new army of digital firefighters. </p>
                    <p>Here's why.</p>
                  </div>
                </div>
                <div>
                  <div className="slds-align-bottom">
                    <a href="https://www.weforum.org/agenda/2020/12/new-digital-army-cybersecurity/" target="_blank" className="tds-button_small tds-button_brand slds-button_neutral slds-button slds-show_inline-block">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="wef-jvc-modal" className="slds-hide">
        <section role="dialog" tabIndex="-1" aria-labelledby="wef-jvc-modal-heading" aria-modal="true" className="slds-modal slds-fade-in-open">
          <div className="slds-modal__container">
            <header className="slds-modal__header">
              <button className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" onClick={() => closeModal('wef-jvc-modal')}>
              <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                <path xmlns="http://www.w3.org/2000/svg" d="M14.3 11.7l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.2-.7-.2-1 0l-6 6.1c-.2.2-.5.2-.7 0l-6-6.1c-.3-.3-.7-.3-1 0l-1 1c-.2.2-.2.7 0 .9l6.1 6.1c.2.2.2.4 0 .6l-6.1 6.1c-.3.3-.3.7 0 1l1 1c.2.2.7.2.9 0l6.1-6.1c.2-.2.4-.2.6 0l6.1 6.1c.2.2.7.2.9 0l1-1c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z"/>
              </svg>
                <span className="slds-assistive-text">Close</span>
              </button>
              <h2 id="wef-jvc-modal-heading" className="slds-modal__title slds-hyphenate">Cybersecurity Career Chats</h2>
              
            </header>
            <div className="slds-modal__content slds-p-around_small" id="modal-content-id-1">
              <div id="wef-video-wrapper" className="wef-video-wrapper slds-align_absolute-center">
              <img
                style={{width: "100%", margin: "auto", display: "block"}}
                className="vidyard-player-embed"
                src="https://play.vidyard.com/R1cykK64oW1uu9EA2EF4pN.jpg"
                data-uuid="R1cykK64oW1uu9EA2EF4pN"
                data-v="4"
                data-type="inline"
              />
              </div>
            </div>
          </div>
        </section>
        <div className="slds-backdrop slds-backdrop_open"></div>
        </div>

        <div id="wef-absa-modal" className="slds-hide">
        <section role="dialog" tabIndex="-1" aria-labelledby="modal-heading-01" aria-modal="true" className="slds-modal slds-fade-in-open">
          <div className="slds-modal__container">
            <header className="slds-modal__header">
              <button className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse" title="Close" onClick={() => closeModal('wef-absa-modal')}>
              <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                <path xmlns="http://www.w3.org/2000/svg" d="M14.3 11.7l6-6c.3-.3.3-.7 0-1l-.9-1c-.3-.2-.7-.2-1 0l-6 6.1c-.2.2-.5.2-.7 0l-6-6.1c-.3-.3-.7-.3-1 0l-1 1c-.2.2-.2.7 0 .9l6.1 6.1c.2.2.2.4 0 .6l-6.1 6.1c-.3.3-.3.7 0 1l1 1c.2.2.7.2.9 0l6.1-6.1c.2-.2.4-.2.6 0l6.1 6.1c.2.2.7.2.9 0l1-1c.3-.3.3-.7 0-1l-6-6c-.2-.2-.2-.5 0-.7z"/>
              </svg>
                <span className="slds-assistive-text">Close</span>
              </button>
              
              
            </header>
            <div className="slds-modal__content slds-p-around_small" id="modal-content-id-1">
              <div id="wef-video-wrapper" className="wef-video-wrapper slds-align_absolute-center">
                <div id="wef-youtube-wrapper"> 
                </div>              
              </div>
            </div>
          </div>
        </section>
        <div className="slds-backdrop slds-backdrop_open"></div>
        </div>
      </div>
    </BaseTemplate>
  );
};

export default CardsQuery;
