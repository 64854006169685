import React from 'react';
import PropTypes from 'prop-types';

const PathCard = ({ title, description, imageUrl,background, index, id, slug }) => {
  const cardWidth = 357;
  const cardHeadHeight = 180;
  const bckgrndWidrth = 554;
  const characterImgSize = 150;
  const rectHeight = 120;
  const circleRadio = characterImgSize / 2;
  const posCircleX = '50%';
  const posCircleY = cardHeadHeight - circleRadio - 1;

  const randPosBgImage = Math.floor(Math.random() * -195); // Max  positionX to the left is -195
  const cardPic = `https://developer.salesforce.com/resources2/wef/${imageUrl}`;
  const cardBackground = `https://developer.salesforce.com/resources2/wef/${background}`;

  const defaultSlug = title.toLowerCase().replace(/ /g, '-');
  const urlTo = slug != null && slug != '' ? slug : `/cybersecurity/${defaultSlug}`;

  const svgTag =
    `<svg class="slds-container--fluid ext-tbz-cards__banner-bg" style="height:${cardHeadHeight}px;" version="1.2">
      <defs>
        <mask id="story-header-mask_${index}" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
          <circle cx="${posCircleX}" cy="${posCircleY}" fill="white" r="${circleRadio}"></circle>
          <rect fill="white" height="${rectHeight}" width="${cardWidth}" x="0" y="0"></rect>
        </mask>
        <linearGradient id="svgCardGradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="#7FC7DE"></stop>
          <stop offset="100%" stop-color="#B6E5F1"></stop>
        </linearGradient>
      </defs>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill="url(#svgCardGradient)"
        mask="url(#story-header-mask_${index})"
      />
      <image mask="url(#story-header-mask_${index})" xlink:href="${cardBackground}" height="100%" width="100%"/>
      <image mask="url(#story-header-mask_${index})" width="100%" height="150" y="40" xlink:href="${cardPic}"></image>
    </svg>`;

  return (
    //  the return() to put your default HTML into the DOM
    <div className="slds-card tds-card wef-card-path">
      <a href={urlTo} className="wef-link--no-underline">
        <div className="wef-card-path_head">
          <div dangerouslySetInnerHTML={{ __html: svgTag }} />
        </div>
        <div className="wef-card-path_body slds-p-around--medium">
          <div className="wef-card-path_title">{title}</div>
          <div className="wef-card-path_description">{description}</div>
        </div>
      </a>
    </div>
  );
};

PathCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string
};

export default PathCard;
