import * as React from "react";

const CustomLeftArrow = ({ onClick }) => (
<svg onClick={() => onClick()}
    className="arrow_left" 
    viewBox="0 0 24 24" 
    id="chevronleft"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8 22l-9.6-9.4c-.3-.3-.3-.8 0-1.1l9.6-9.4c.3-.3.7-.3 1 0l1 1c.3.3.3.7 0 1l-7.6 7.4c-.3.3-.3.8 0 1.1l7.5 7.4c.3.3.3.7 0 1l-1 1c-.2.2-.6.2-.9 0z"/>
</svg>
);
const CustomRightArrow = ({ onClick }) => {
return <svg onClick={() => onClick()}
            className="arrow_right" 
            viewBox="0 0 24 24" 
            id="chevronright" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="M8.3 2l9.5 9.5c.3.3.3.7 0 1L8.3 22c-.3.2-.8.2-1.1 0l-1-1c-.2-.3-.2-.8 0-1.1l7.6-7.4c.2-.3.2-.7 0-1L6.3 4.1C6 3.8 6 3.3 6.3 3l1-1c.3-.2.7-.2 1 0z"/>
        </svg>;
};


export {
CustomLeftArrow,
CustomRightArrow,
};