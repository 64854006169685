import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
    CustomLeftArrow,
    CustomRightArrow,
    } from "./customArrows";
import InterviewCard from './InterviewCard';

const carouselData = graphql`
  query carouselData {
    allInterviewsDataJson {
      edges {
        node {
          favoriteBadges {
            Title
            iconUrl
            type
            url
          }
          description
          funFacts {
            answer
            question
            url
            icon
          }
          hero {
            backgroundImage
            location
            personaImage
            name
            quoteText
            title
          }
          q_aQuote
          questionsAnswers {
            answer
            question
          }
          role
          slug
          backgroundNumber
        }
      }
    }
  }
`;

function shuffleItems(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

const CarouselQuery = () => (
  <StaticQuery
    query={carouselData}
    render={ data => {
      let interviewData = [];
      data.allInterviewsDataJson.edges.forEach((edge) => {
        const item = edge.node;
        if( item !== null ){
            interviewData.push(item);
        }
      });
      interviewData = shuffleItems(interviewData);
      return (
        <InterviewsCards interviewData={interviewData}/>
      )
    }}
  />
);

const InterviewsCards = ({interviewData}) => {
  let interviewElements = [];

  interviewData.map((item, index) => {
    let k = `interview-card-${index}`;
    interviewElements.push(
      <InterviewCard
        key={k}
        item={item}
      />
    )
  });

  const responsive = {
    desktopXL: {breakpoint: { max: 100000, min: 4000 }, items: 3, slidesToSlide: 3},
    desktop: {breakpoint: { max: 4000, min: 1024 }, items: 3, slidesToSlide: 3},
    tablet: {breakpoint: { max: 1024, min: 640 }, items: 2, slidesToSlide: 2},
    mobile: {breakpoint: { max: 640, min: 0 }, items: 1, slidesToSlide: 1}
  };
  return (
    <div className="slds-container--center slds-container_x-large slds-p-top--small">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        partialVisible={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay="true"
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all 1.5s linear"
        transitionDuration={1000}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style">
        {interviewElements}
      </Carousel>
    </div>
  );
}

export default CarouselQuery;
